/*
 * @Author: 刘康宁 869161591@qq.com
 * @Date: 2022-07-21 14:21:39
 * @LastEditors: YGQ
 * @LastEditTime: 2024-08-14 09:38:37
 * @FilePath: /oneid/src/api/element.js
 * @Description: 按需引入 element
 */
import {
  Alert,
  Autocomplete,
  Avatar,
  Button,
  Descriptions,
  DescriptionsItem,
  Dialog,
  Divider,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Form,
  FormItem,
  Icon,
  Input,
  Link,
  Loading,
  Message,
  MessageBox,
  TabPane,
  Table,
  TableColumn,
  Tabs,
  Tag,
  Tooltip,
  Upload,
  Image
} from 'element-ui'
const element = {
  install: function (Vue) {
    Vue.use(Alert)
    Vue.use(Autocomplete)
    Vue.use(Avatar)
    Vue.use(Button)
    Vue.use(Descriptions)
    Vue.use(DescriptionsItem)
    Vue.use(Dialog)
    Vue.use(Divider)
    Vue.use(Dropdown)
    Vue.use(DropdownItem)
    Vue.use(DropdownMenu)
    Vue.use(Form)
    Vue.use(FormItem)
    Vue.use(Icon)
    Vue.use(Input)
    Vue.use(Link)
    Vue.use(Loading)
    Vue.use(TabPane)
    Vue.use(Table)
    Vue.use(TableColumn)
    Vue.use(Tabs)
    Vue.use(Tag)
    Vue.use(Tooltip)
    Vue.use(Upload)
    Vue.use(Image)
    Vue.prototype.$loading = Loading.service
    Vue.prototype.$msg = Message
    Vue.prototype.$msgbox = MessageBox
  }
}
export default element
